#schedules-container .delete-btn:not([disabled]) {
    background-color: #da2a2a;
    color: #ffffff;
}

#schedules-container .delete-btn:hover {
    background-color: #bd2323;
}

#schedules-container .schedule-item-head {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #e9e9e9;
    padding: 0.5rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
}

#schedules-container .schedule-item-head p {
    padding: 0 1rem;
}

#planes-container {
    padding: 0 2rem;
}

#planes-container .planes {
    position: -web-sticky;
    position: sticky;
    top: 1rem;
}

#planes-container h6 {
    margin-bottom: 2rem;
    background-color: #e9e9e9;
    border-radius: 1rem;
    padding: 0.3rem 0;
}

#planes-container .MuiButton-root {
    width: 7rem;
    margin: 0.5rem;
    color: #ffffff;
}

.plans-btn-list {
    justify-content: center;
    display: flex;
    padding: 0.5rem;
    flex-wrap: wrap;
}

.schedule-item {
    float: left;
    background-color: #f0f0f0;
    border-radius: 0.5rem;
    width: 12rem;
    height: 7.3rem;
    font-size: 10pt;
    border: 0.1rem solid #cacaca;
    padding: 0.3rem;
    margin: 0.4rem;
    transition: 0.2s linear;
    cursor: pointer;
}

.schedule-item:hover {
    box-shadow: 0 0 0.5rem #919191;
}

.schedule-item .MuiGrid-item {
    padding: 0 0.3rem;
}

.schedule-item-category-stage {
    text-align: center;
    font-size: 1.5em;
    margin: 0.5rem 0;
}

.schedule-item-category-stage .MuiGrid-item {
    padding: 0.4rem 0;
    border-radius: 0.5rem;
    font-size: 0.7em;
    margin: 0 0.2rem;
    color: #ffffff;
}

.schedule-item-duration {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}

.schedule-create-button:disabled {
    background-color: #cacaca !important;
}
