.referee__competitions {
    background-color: #f7f7f7;
    padding: 2rem 0;
    .referee__competitions_container {
        padding-top: 1rem;
    }
    .referee__competitions_card {
        width: 48%;
        background-color: #ffffff;
        border-radius: 1rem;
        padding: 2rem;
        margin: 1rem 1rem 1rem 0;
        transition: 0.2s;
        -webkit-box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.33);
        -moz-box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.33);
        box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.33);

        &:hover {
            transition: 0.2s;
            color: #868686;
            -webkit-box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.33);
            -moz-box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.33);
            box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.33);
            h6 {
                transition: 0.2s;
                color: #1b6bd3;
            }
        }

        h6 {
            transition: 0.2s;
            color: #474747;
        }

        p {
            color: #969696;
        }
    }
}
