.upload-music-dialog {
    .upload-music-dialog__dropzone {
        background-color: #f3f3f3;
        border: 0.2rem dashed #e0e0e0;
        padding: 2rem;
        text-align: center;
        outline: none;

        .upload-music-dialog__dropzone-icon {
            font-size: 4em;
        }
    }

    .upload-music-dialog__files-list li {
        padding: 0.5rem;
    }

    .upload-music-dialog__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .upload-music-dialog__upload-button:enabled {
            color: #33994d;
            font-weight: 700;
            border-radius: 1rem;
            border: #33994d solid 0.2rem;
            transition: 0.2s linear;

            &:hover {
                transition: 0.2s linear;
                background-color: #33994d;
                color: #f3f3f3;
            }
        }

        .upload-music-dialog__actions__text-result {
            display: flex;
            align-items: center;

            .status-icon {
                height: 1.2rem;
                margin: 1rem;
                width: 1.2rem;
                border-radius: 1rem;
            }
            .status-icon-success {
                background-color: #33994d;
            }
            .status-icon-error {
                background-color: #bd3434;
            }
        }
    }
}
