.shuffle-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    padding: .5rem;
    bottom: 2rem;
    right: 2rem;
    z-index: 1;
}

.shuffle-buttons .MuiFab-root {
    margin: .5rem;
}

.shuffle-list {
    margin: 2rem 0;
}

.shuffle-list .MuiListItem-root {
    margin: 0;
    padding: 0;
}

.shuffle-list .MuiListItem-root .MuiIconButton-root {
    margin: 0 .2rem;
}

.plan-list {
    margin-right: 2rem;
}

.MuiList-root .plan-btn.active {
    background-color: #1b6bd3;
    border-radius: .3rem;
    color: #ffffff;
}