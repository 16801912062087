.user-popup {
    height: 500px;
    padding: 0 auto;
}

.tabs-margin {
    margin: 1rem 0;
}

.user-popup .MuiTabs-indicator {
    background-color: #1b6bd3;
}

.MuiDialogContent-root:first-child.user-popup {
    padding-top: 0;
}