.home {
    padding: 2rem 0;
}

.contacts {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 720px) {
        display: block;
        text-align: center;
    }
}

.cost,
.phone {
    font-size: 18px;
    color: grey;
}

.bordered {
    padding: 1rem 0;
    border-top: 1px solid rgb(50, 174, 223);
    border-bottom: 1px solid rgb(50, 174, 223);
}

.title {
    margin: 2rem 0;
    text-align: center;
    color: rgb(50, 174, 223);
}

.feature {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 720px) {
        display: block;

        & .text {
            text-align: center;
            padding-top: 0.5rem;
        }
    }

    & .image {
        text-align: center;
        min-width: 300px;
    }

    & span {
        display: block;
        font-weight: bold;
        font-style: italic;
    }
}
