.penalties-input {
    padding: 0;
    text-align: center;
}

#protocol-complexity-container .field {
    width: 7em;
    margin: 5px;
}

#protocol-complexity-container .division-row {
    height: 10px;
    border-bottom: 1pt solid #cccccc;
}

#penalties-panel .subtitle {
    text-align: center;
}

#complexity-penalties-table{
    margin: auto;
}

.complexity-td{
    padding: 0 5px;
}

#penalties-panel .title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 5px;
}
