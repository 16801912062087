.license-status {
    width: 1.3em;
    height: 1.3em;
    border-radius: 1rem;
    margin-right: 0.5rem;

    &__not-assigment {
        background-color: gray;
    }
    &__active {
        background-color: green;
    }
    &__expires {
        background-color: orange;
    }
    &__expired {
        background-color: red;
    }
}
