.MuiGrid-item {
    padding: 1rem 0;
}

.main-menu {
    padding: 0;
    background-color: #f3f3f3;
}

.main-menu .MuiTab-textColorPrimary.Mui-selected {
    color: #1b6bd3;
}

.main-menu .MuiTabs-indicator {
    background-color: #1b6bd3;
}

.main-content {
    padding: 0;
    background-color: #ffffff;
}

.main-content .MuiTab-wrapper {
    font-size: 1.1em;
}

.sub-menu .Mui-selected {
    color: #ffffff;
    z-index: 1;
}

.sub-menu .MuiTabs-indicator {
    border-radius: 0.3rem;
    height: 100%;
    background-color: #f50057;
}

.competition-title {
    margin: 1.5rem 0;
    font-weight: 700;
}

.main-view-head {
    margin: 1rem 0;
}
