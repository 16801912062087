.athlete-form {
    margin-top: 1rem;
}

.athlete-form .MuiGrid-item {
    padding: 0;
}

.competition-card {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.competition-card .competition-card__header {
    padding: 1.5rem 3rem;
    background-color: #1b6bd3d5;
    min-height: 6rem;
    height: 12rem;
    max-height: 12rem;
}

.competition-card .competition-card__header .MuiCardHeader-title {
    color: #ffffff;
}

.competition-card .competition-card__header .MuiCardHeader-subheader {
    color: #e0e0e0;
}

.competition-card .competition-card__content {
    padding: 0.5rem 3rem;
    display: flex;
    flex-direction: column;
    flex: 2 1;
}

.competition-card .competition-card__footer {
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    flex: 0;
}

.competition-card .competition-card__content svg {
    color: #606060;
}

.competition-card .competition-card__content .MuiGrid-grid-xs-2 {
    display: flex;
    justify-content: start;
}

.competition-card .competition-card__content .competition-card__content-categories {
    display: flex;
    flex-flow: row wrap;
}

.competition-card .competition-card__content .competition-card__content-categories span {
    padding-right: 1rem;
}

.request-dialog__header {
    padding: 0 1.8rem;
}

.request-dialog__header__tabs .MuiTab-root {
    font-weight: 600;
    font-size: 0.95em;
}

.request-dialog__header__tabs .Mui-selected {
    color: #0c53b1;
}

.request-dialog__content {
    height: 80vh;
}

.request-dialog__music-request-item {
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid #e0e0e0;
}

.request-dialog__expansion-panel__card {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin: 0.3rem;
}

.request-dialog__expansion-panel-summary-title {
    padding: 0;
    margin-right: 1rem;
}

.request-dialog__expansion-panel__request-count-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5rem;
    color: #ffffff;
    margin: 0 0.3rem;
    padding: 0.5rem;
}

.request-dialog__expansion-panel__card-header {
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0.5rem 1.5rem;
    color: #ffffff;
}

.request-dialog__expansion-panel__card-header h6 {
    margin-right: auto;
}

.request-dialog__expansion-panel__card-content {
    padding: 0.5rem 1.5rem;
}

.request-dialog__expansion-panel__card-details p {
    margin: 0.2rem 0 0 0;
    font-size: 0.9em;
}

.request-dialog__expansion-panel__card-actions {
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
}

.request-dialog__actions .request-dialog__actions-entry-fee {
    padding: 0 1rem;
    margin-right: auto;
}

.request-dialog__actions .request-dialog__actions-entry-fee button {
    margin: 0 1rem;
}

.select-athletes-dialog {
    height: 100vh;
    padding-top: 0;
}

.select-athletes-dialog__athletes-list {
    padding-top: 0;
}

.select-athletes-dialog__athletes-list .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
}

.select-athletes-dialog__requests {
    overflow-y: auto;
}

.select-athletes-dialog__helper-text {
    display: flex;
    justify-content: center;
}

.select-athletes-dialog__requests__item {
    padding: 1rem 0;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.select-athletes-dialog__requests__item-active {
    background-color: #f3f3f3;
    border-radius: 1rem;
}

.select-athletes-dialog__requests__item-content {
    padding: 0 1rem;
    align-items: center;
}

.select-athletes-dialog__requests__item-content__bottom {
    padding-top: 0.5rem;
    display: flex;
    justify-content: flex-start;
}

.select-athletes-dialog__requests__item-content__bottom div {
    padding: 0 1rem;
    display: flex;
    align-items: flex-end;
}

.select-athletes-dialog__requests__item-content__bottom div p {
    padding: 0 0.5rem;
}

.select-athletes-dialog__requests__item-content__bottom div svg {
    color: rgba(0, 0, 0, 0.54);
}

.select-athletes-dialog__requests__item-content__left {
    display: flex;
    flex-direction: column;
}

.select-athletes-dialog__requests__item-content .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
}

.select-athletes-dialog__requests__item-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
}

.select-athletes-dialog__requests__item-additions {
    padding: 0 1.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.select-athletes-dialog__requests__item-additions .MuiFormControl-root {
    margin: 0;
}

.select-athletes-dialog__requests__item-additions .MuiGrid-item {
    padding: 0 0 1rem 0;
}

.select-athletes-dialog__requests__item-additions-icon {
    display: flex;
    align-self: flex-end;
    margin-bottom: 0.2rem;
}

.select-athletes-dialog__requests__item-additions-icon svg {
    font-size: 1.8rem;
    color: rgba(0, 0, 0, 0.54);
}

.select-athletes-dialog__requests__item-error {
    border-radius: 0 0 1rem 1rem;
    background-color: #ee3a3a21;
}
.select-athletes-dialog__requests__item-error p {
    color: #ee3a3a;
    padding: 0 2rem;
}

.select-athletes-dialog__athletes-list h6 {
    padding: 1rem;
}

.select-athletes-dialog__athletes-list p {
    text-align: center;
    margin-top: 1rem;
}

.select-athletes-dialog__athletes-list .MuiListItemIcon-root {
    display: flex;
    justify-content: center;
}

.adding-athlete-by-license-id__form {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.adding-athlete-by-license-id__form .MuiFormControl-root {
    width: 90%;
    font-size: 0.9em;
}

.adding-athlete-by-license-id__add-button {
    background-color: #1b6bd3;
    color: white;
}
.adding-athlete-by-license-id__add-button:hover {
    background-color: #0c53b1;
}

.request-dialog__expansion-panel__request-categories-container {
    padding-top: 0.5rem;
}

.request-dialog__expansion-panel__request-category-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5rem;
    color: #ffffff;
    height: 2em;
    margin: 0.3rem;
    padding: 0.5rem;
}

.request-dialog__expansion-panel__request-category-badge:first-child {
    margin-left: 0;
    margin-right: 0.3rem;
}

.select-athletes-dialog_aside {
    background-color: white;
    padding-bottom: 0;
}
