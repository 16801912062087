h4 {
    line-height: 1.5;
}

.select-number {
    display: inline-block;
    vertical-align: middle;
    width: 8%;
}

.exclamation-mark {
    display: inline-block;
    vertical-align: middle;
    width: 8%;
    padding-left: 4px;
    padding-right: 4px;
    color: #f89406;
}

.numbered-select {
    width: 84%;
    display: inline-block;
    margin-bottom: 10px;
}

.akv_center {
    text-align: center;
}

.akv_left {
    float: left;
}

.akv_right {
    float: right;
}

.akv_vertical_center {
    position: inherit;
    vertical-align: middle;
}

.akv_clear {
    clear: both;
}

.akv_bold {
    font-weight: bold;
}

.akv_padding_left {
    padding-left: 1em;
}

.spinner {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #337ab7; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 100px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-background {
    height: 200px;
}

.margin-top-bottom-10 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.margin-top-bottom-20 {
    margin-bottom: 20px;
    margin-top: 20px;
}
