$penaltiesRelativeWidth: 4.5%;

.rating-view-main {
    background-color: #000000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: azure;
    text-align: center;
    font-size: 3vmin;
}
.table-header {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 1.2ch;
    font-size: 1.3em;
    font-weight: 500;
    height: 13%;
}
.age-category {
    font-weight: 900;
    font-size: larger;
    max-height: 60px;
}
.world-code {
    vertical-align: top;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}
.rating-table {
    width: 100%;
    min-height: 80%;
    max-height: 90%;
    border: 1px solid rgba(250, 250, 250, 0.966);
    text-align: center;
}
.table-row {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    border-top: 0.5rem solid azure;
    border-bottom: 0.5rem solid azure;
}
td,
th {
    text-align: center;
}
tr {
    border: 1px solid rgba(250, 250, 250, 0.966);
    text-align: center;
}
.athlete-column {
    text-align: left;
    width: 30%;
}
.atheles-names {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.scores {
    font-weight: bold;
}
.penalties-columns {
    padding: 0;
    font-weight: bold;
    color: rgb(230, 98, 98);
    width: $penaltiesRelativeWidth;
}
.penalties-headers {
    padding: 0;
    width: $penaltiesRelativeWidth;
}
.rank-column {
    padding: 0;
    width: 6%;
}
