.category-tabs .MuiTab-root {
    min-width: 1rem;
    width: 5rem;
}

.age-tabs .age-tabs-actions {
    margin-left: auto;
}

.age-tabs .age-tabs-actions button {
    margin: 0;
}

.age-tabs .MuiTab-root {
    min-width: 1rem;
    width: 8rem;
}

.category-tabs .Mui-selected {
    z-index: 1;
    color: #ffffff;
}

.category-tabs .MuiTabs-indicator {
    height: 100%;
    background-color: #1b6bd3;
    border-radius: 0.3rem;
}

.add-request-fixed-bar {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    padding: 0.5rem;
    z-index: 5;
}

.add-request-fixed-bar .MuiFab-root {
    margin: 0.5rem;
}

.entry-fee-dialog__trainer-summary .MuiExpansionPanelSummary-content {
    display: flex;
    justify-content: space-between;
}

.entry-fee-dialog__trainer-details {
    padding: 0.5rem 0;
}

.request-music-status {
    display: flex;
    align-items: center;
}
