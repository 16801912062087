.referee-score-header{
    background-color: rgba(255, 255, 255, 0.8);
    text-align: right;
    font-size: 1.5em;
    transform: translate3d(0,0,0);
}

.referee-score-header .score {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-size: 1.2em;
    width: 3em;
    background-color: #ddd;
    margin-bottom: 0.2em;
    margin-top: 0.2em;
    border-radius: 20px;
}

.fixed-to-top{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: inherit;
}

.referee-score-header-container{
    height: 70px;
    width: 100%;
}
