.category-rf {
    background-color: #ffa1a146;
}

.category-fig {
    background-color: #bed1ff46;
}

.category-item {
    padding: 0.5rem 1rem !important;
}
