html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: "Roboto";
    font-size: 14px;
}

a {
    padding: 0 0.3rem;
}

a:hover {
    text-decoration: none;
}

.icon-color--success {
    color: #29b658;
}

.icon-color--failure {
    color: #b62929;
}

.MuiTypography-h4 {
    font-weight: 500;
}

.extended-icon-fab-margin {
    margin-right: 0.5rem;
}

.MuiFab-root {
    background-color: #1b6bd3;
    color: #ffffff;
}

.MuiFab-root:hover {
    background-color: #0c53b1;
}

.MuiFab-extended.MuiFab-sizeSmall {
    padding: 0 1rem;
}

/* .MuiButton-textPrimary {
    color: #ffffff;
    background-color: #1b6bd3;
} */

/* .MuiButton-textPrimary:hover {
    background-color: #0c53b1;
} */

/* .MuiCheckbox-colorPrimary.Mui-checked {
    color: #1b6bd3;
} */

/* .MuiPickersBasePicker-container .MuiButton-root {
    padding: 0;
    margin: 0;
} */

/* .MuiButton-root {
    padding: 0.7rem 1rem;
    margin: 1rem 0;
} */

.MuiButton-label span {
    display: flex;
    align-items: center;
    text-align: center;
}

.MuiFormHelperText-filled.warning {
    background-color: #fcf8e3;
    color: #8a6d3b;
    border-radius: 1rem;
    padding: 1.5rem !important;
}

.MuiExpansionPanelSummary-root {
    background-color: #ffffff;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
    background-color: #e9e9e9;
}

.MuiExpansionPanelDetails-root .MuiGrid-item {
    /* .MuiExpansionPanelDetails-root .MuiIconButton-root { */
    padding: 0.3rem 0;
}

.main-views {
    margin: 1rem 0;
}

/* .MuiFormHelperText-root.Mui-error {
    padding: 0.5rem 0;
    font-size: 1em;
} */

.MuiTooltip-popper > * {
    font-size: 0.9em;
}

.grid-item-clear .MuiGrid-item {
    padding: 0;
}
