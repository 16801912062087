#protocolMainReferee .status {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
}

#protocolMainReferee .drop-down {
    min-width: 15em;
}

#protocolMainReferee .status-btn {
    width: 100px;
}

.save-status-element{
    margin: 10px;
    display: inline-block;
}

.buttons-wraper{
    display: inline-block;
}