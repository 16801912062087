.register-page {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 5%;

    h4 {
        text-align: center;
        padding: 1rem 0;
    }

    form {
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .register-page__error-text {
        padding: 1rem 0;
        text-align: center;
    }

    .register-page__actions {
        display: flex;
        justify-content: center;
    }
}
