.performance-view-main {
    display: grid;
    grid-template-rows: 10% 10% 40% 40%;
    background-color: #000000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: azure;
    text-align: center;
    font-size: 6vmin;
}
.first-line {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}
.score-rank-line {
    display: inline-grid;
    grid-template-columns: auto auto;
    align-items: center;
}
.table-line {
    display: inline-grid;
    grid-template-columns: repeat(6, auto);
}
.just-2-table-columns {
    border-right: 0.5rem solid azure;
    grid-template-rows: auto auto;
}
.last-2-table-columns {
    grid-template-rows: auto auto;
}
.score-value {
    font-weight: bolder;
    font-size: 3.6em;
    line-height: 1;
    color: greenyellow;
}
.rank-value {
    font-weight: bolder;
    font-size: 3.6em;
    line-height: 1;
    color: rgb(255, 230, 0);
}
.scores {
    font-size: 2.5em;
    font-weight: bold;
}
.performance-penalties {
    font-size: 2.5em;
    font-weight: bold;
    color: rgb(230, 98, 98);
}
.athletes {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: auto;
    border-bottom: 0.5rem solid azure;
    overflow: hidden;
    font-size: 1.3em;
}
.marquee {
    white-space: nowrap;
    animation: scroll 20s infinite linear;
}
@keyframes scroll {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
    0% {
        transform: translate(100%, 0);
    }
}
