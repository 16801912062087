.perf-search-results .MuiExpansionPanel-root {
    margin: 1rem 0;
}

.perf-search-results .MuiExpansionPanelDetails-root {
    padding: 0;
}

.perf-search-results .category-panel {
    margin: 1rem 0;
}

.perf-search-results .category-panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: #f3f3f3;
}