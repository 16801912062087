.music-player {
    outline: none;
    position: fixed;
    left: 0;
    bottom: 0;
    svg {
        color: #1b6bd3;
    }

    .rhap_progress-indicator,
    .rhap_volume-indicator {
        background-color: #1b6bd3;
    }

    .rhap_progress-filled {
        background-color: #1b6bd3;
    }

    .rhap_progress-container,
    .rhap_volume-bar-area,
    .rhap_button-clear {
        outline: none;
    }
}

.music-library__files-list {
    padding-bottom: 7rem;

    li {
        padding: 0.5rem 0;

        &:hover {
            background-color: #f1f1f1;
            border-radius: 1rem;
        }

        .music-library__file-item__textfield {
            padding: 0 1rem;
        }

        .music-library__file-item__actions {
            display: flex;
            flex-wrap: nowrap;
            margin-left: auto;
        }
    }
}
