.beamer-main{
    display: grid;
    background-color: #000000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: azure;
    text-align: center;
    font-size: 4em;
}