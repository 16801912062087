.MuiTableCell-head {
    padding: 1rem 0.5rem;
    background-color: #e9e9e9;
}

.MuiTableCell-body {
    padding: 1rem 0.5rem;
}

.button-cell {
    padding: 0.5rem;
    width: 3rem;
}

.data-table .single-line {
    white-space: nowrap;
}

.clickable:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}

.data-table .MuiTextField-root {
    width: auto;
}
