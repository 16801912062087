.online-results {
    h4 {
        padding: 2rem 0;
    }

    .sub-menu {
        padding-bottom: 1rem;
    }

    h5 {
        padding: 1rem 0;
        font-size: 1.9em;
        font-weight: 700;
    }

    .data-table {
        padding: 2rem 0;
    }
}
