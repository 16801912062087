.scores-table {
    .penalty,
    .score {
        text-align: center;
        width: 5em;
    }

    .styled-cell-header {
        font-weight: 700;
        background-color: #c9c9c9;
    }

    .styled-cell-row {
        font-weight: 700;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .MuiTableCell-body {
        padding: 0.5rem 0.1rem;
    }

    th {
        border-left: 0.1rem solid #dddddd;
        border-right: 0.1rem solid #dddddd;
    }
    td {
        border-left: 0.1rem solid #e9e9e9;
        border-right: 0.1rem solid #e9e9e9;
    }

    tr td {
        font-size: 1em;
    }
    td,
    th {
        text-align: center;
    }

    .btn {
        padding: 0.1rem;
        margin-bottom: 0;
        margin-top: 0.3rem;
        height: 2.5rem;
        min-width: 3.5rem;

        &.Mui-disabled {
            color: inherit;
            opacity: 1;
        }
    }

    .MuiTableCell-head {
        padding: 0.5rem 0.3rem;

        .cell-head-title--i18n {
            text-transform: uppercase;
        }
    }

    .translate-scores-button {
        margin-top: 0.5rem;
    }

    .button--shown-scores {
        color: green;
        background-color: #e0e0e05e;
    }

    .score_table__row__item_arrows {
        padding-top: 0.5rem;
        display: flex;
        justify-content: center;

        .arrow-active--up {
            color: green;
            background-color: #e0e0e05e;
            svg {
                transform: scale(1.3);
            }
        }

        .arrow-active--down {
            color: red;
            background-color: #e0e0e05e;
            svg {
                transform: scale(1.3);
            }
        }
    }
}
