.protocol-container {
    padding: 2rem 0;
}

div.protocolTitle {
    text-align: center;
    font-size: medium;
    font-weight: bold;
    margin: 2rem 0;
}

.number-input {
    width: 100px;
    margin: 0 auto;
    text-align: center;
}

#refereeHeader {
    margin: 1rem auto;
    background-color: #e9e9e9;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#refereeHeader .new-container {
    border-top: 1px solid #ddd;
    margin-top: 1px;
    padding: 10px;
}

#refereeHeader .competition_name {
    font-weight: bold;
    height: 1.5em;
    line-height: 1.5em;
    overflow: hidden;
}

#refereeHeader .description_line {
    overflow: hidden;
    height: 1.5em;
    line-height: 1.5em;
    overflow: hidden;
}

#refereeHeader .header_caption {
    width: 20%;
    float: left;
    overflow: hidden;
}

#refereeHeader .totalScore {
    color: #000;
}

#refereeHeader .clear {
    clear: both;
}
