.previous-performance-scores-container {
    background-color: #e2eff3;
    margin-bottom: 1rem;
    border-radius: 1rem;
}

.previous-performance-scores-header {
    border-radius: 1rem 1rem 0 0;
    background-color: #d0e3e9;
    text-align: center;
    font-weight: 500;
    padding: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.previous-performance-scores-body {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
}

.previous-performance-scores {
    font-weight: bold;
    text-align: center;
}

.score-label {
    text-align: center;
}

.vertical-line {
    width: .09rem;
    background-color: #242424;
    height: auto;
}