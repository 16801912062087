.age-category-btn-list > .age-category-btn:nth-child(even) {
    margin: 0.3rem 0.6rem;
}

.age-category-btn {
    height: 3.4rem;
    display: block;
    margin: 0.3rem 0;
}

.success {
    background-color: #1b6bd3;
    color: #ffffff;
}

.success:hover {
    background-color: #0c53b1;
    color: #ffffff;
}

.default {
    background-color: #e6e6e6;
}
