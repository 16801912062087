.total {
    margin-left: 1em;
    font-size: large;
    font-weight: bold;
}

 .list-group {
    padding-left: 0;
    margin-bottom: 0px;
}

.panel-body {
    padding: 0px;
}

.criterion-group {
    display: flex;
    justify-content: center;
}