.competitions-list-page {
    margin: 1rem auto;
}

.competitions-list-page-header {
    padding: 1rem 0;
}

.competitions-list-page-content .MuiListItem-root {
    padding: 1rem ;
    border-bottom: .1rem solid #dddddd;
    cursor: pointer;
}

.competitions-list-page-content .MuiListItem-root:hover {
    background-color: #e9e9e9;
}
  