.indicator-container{
    position: absolute;
    right: 0;
    left: 0;
    min-width: 100%;
    padding: 0;
    margin: 0;
    bottom: 0;
    overflow: hidden;
}

.indicator-container .MuiLinearProgress-root{
    color: #1b6bd3;
    height: .4rem;
}
.indicator-container .MuiLinearProgress-bar {
    background-color: #1b6bd3;
}