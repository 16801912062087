.display {
    background-color: #000000;
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
}

.display .athlete{
    height: 32vh;	
    overflow: hidden;
    position: relative;
}

.display .athlete h3 {
    font-size: 28vh;
    color: beige;
    position: absolute;
    width: auto;
    height: 100%;
    margin: 0;
    white-space:nowrap;
    /* Starting position */
    -moz-transform:translateX(100%);
    -webkit-transform:translateX(100%);	
    transform:translateX(100%);
}
    /* Move it (define the animation) */
@-moz-keyframes running-text {
    0%   { -moz-transform: translateX(1920px); }
    100% { -moz-transform: translateX(-100%); }
}

@-webkit-keyframes running-text {
    0%   { -webkit-transform: translateX(1920px); }
    100% { -webkit-transform: translateX(-100%); }
}

@keyframes running-text {
    0%   { 
        -moz-transform: translateX(1920px); /* Firefox bug fix */
        -webkit-transform: translateX(1920px); /* Firefox bug fix */
        transform: translateX(1920px); 		
    }
    100% { 
        -moz-transform: translateX(-100%); /* Firefox bug fix */
        -webkit-transform: translateX(-100%); /* Firefox bug fix */
        transform: translateX(-100%); 
    }
}

.display .score-item {
    display: inline-block;
    line-height: 19vh;
    font-size: 8vw;
    color: beige;
    width: 25%;
    text-align: center;
    height: 40vh;
}

.display .score-0 {
    background-color: #202020;
}

.display .score-1 {
    background-color: #101010;
}


.display .details {
    width: 100%;
    padding-left: 9vh;
    padding-right: 9vh;
}

.display .place {
    display: inline-block;
    font-size: 8vw;
    color: beige;
    width: 50%;
    line-height: 28vh;
    text-align: right;
}

.display .total-scores {
    display: inline-block;
    font-size: 8vw;
    color: beige;
    width: 50%;
    line-height: 28vh;
}

.display .header-row{
    height: 10vh;
    line-height: 12vh;
    font-size: 7vh;
    color: beige;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    padding-left: 2vh;
    padding-right: 2vh;
}

.display .performance-row{
    height: 7.6vh;
    font-size: 5vh;
    color: beige;
    line-height: 7.6vh;
    margin-left: 2vh;
    margin-right: 2vh;
    margin-bottom: 0.4vh;
}

.display .athlete-place {
    display: inline-block;
    background-color: darkred;
    text-align: center;
    width: 7.6vh;
}

.display .name-scores-container {
    display: inline-block;
    width: calc(100% - 7.6vh);
    padding-left: 2vh;
    padding-right: 2vh;
}

.display .athlete-name {
    display: inline-block;
    vertical-align: bottom;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20vh);
}

.display .athlete-scores {
    float: right;
    line-height: 7.6vh;
}

.display .schedule-time {
    display: inline-block;
    background-color: darkred;
    text-align: center;
    width: 23vh;
}

.display .schedule-name-category-container {
    display: inline-block;
    width: calc(100% - 23vh);
    padding-left: 2vh;
    padding-right: 2vh;
}

.display .schedule-athlete-name {
    display: inline-block;
    vertical-align: bottom;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    width: calc(100% - 34vh);
}

.display .schedule-category {
    float: left;
    line-height: 7.6vh;
    width: 24vh;
}

.display .schedule-stage {
    float: left;
    line-height: 7.6vh;
    width: 9vh;
}