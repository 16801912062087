.scores-table,
.scores-result {
    margin: 1rem 0;
}

.scores-result mark {
    background-color: #e9e9e9;
    border-radius: 1rem;
    padding: .3rem .7rem;
    font-weight: 700;
}