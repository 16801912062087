.nav-bar {
    background-color: #2e333d;
    .app-logo {
        a {
            display: flex;
            align-items: center;
            line-height: 40px;
            text-decoration: none;
            .logo-text {
                color: #ffffff;
                margin-left: 5px;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    .nav-links {
        display: flex;

        list-style: none;
        min-height: 7rem;

        flex-wrap: wrap;

        .lngForm {
            margin-top: 0.6rem;
            height: inherit;
            width: inherit;

            label {
                color: #bdbbbb;
            }
        }
        .selectLng {
            // need to fix
            // ::before {
            //     border-color: #dedede;
            // }
            // ::after {
            //     border-color: #dedede;
            // }
            // .icon {
            //     fill: #bdbbbb;
            // }
            color: #bdbbbb;
            margin-top: 25%;
        }

        a {
            // min-width: 8.5rem;
            width: 8.5rem;
            height: 7rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #dedede;
            text-decoration: none;
            font-size: 0.85em;
            font-weight: 500;
            border-bottom: 0.2rem solid #dedede00;
            cursor: pointer;
            p {
                text-align: center;
                margin: auto;
                transition: 0.3s;
            }
            img {
                margin-top: 1rem;
                height: 2.5rem;
                transition: 0.3s;
            }
            transition: 0.3s;
            &:hover {
                border-bottom: 0.2rem solid #ffffff;
                transition: 0.3s;
                background-color: #41485777;
                img {
                    transition: 0.3s;
                    transform: scale(1.2);
                }
                p {
                    transition: 0.3s;
                    color: #ffffff;
                }
            }
        }
    }
    .nav-bar__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 1230px) {
        .nav-bar__container {
            justify-content: space-around;
        }
        .app-logo {
            justify-items: center;
            margin: 2rem 0;
        }
        .nav-links {
            justify-content: center;
            justify-items: center;
        }
    }
}
