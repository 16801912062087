.referee_page__judge-item {
    width: 100%;
    display: block;
    background-color: #eeeeee;
    border-radius: 1rem;
    padding: 0;

    .judge-item__header {
        padding: 1rem 2rem;
        background-color: #2e333de0;
        border-radius: 1rem 1rem 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;

        .performance-status {
            border: 0.15rem solid #ffffff;
            width: 26px;
            height: 26px;
            border-radius: 50%;
        }

        .green {
            background-color: rgb(0, 141, 0);
        }

        .red {
            background-color: rgb(240, 0, 0);
        }

        .orange {
            background-color: rgb(240, 120, 0);
        }

        .blue {
            background-color: rgb(0, 0, 240);
        }
    }

    .judge-item__body {
        padding-top: 2rem;

        .judge-item__body_scores {
            padding: 1rem 0;

            display: flex;
            justify-content: center;

            .score,
            .penalty {
                width: 15rem;
                padding: 0.2rem 0;
                b {
                    padding: 0 0.4rem;
                }
            }
        }

        .judge-item__body_athletes {
            padding: 0.2rem 0;
            text-transform: none;
        }
    }

    .judge-item__footer {
        display: flex;
        padding: 0 2rem 1rem 2rem;
        justify-content: space-between;
        color: #606060;
        text-transform: none;
    }
}
