.settings-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5ch;
    margin-left: 5ch;
    grid-column-gap: 5em;
    grid-row-gap: 5em;
}
.slideUpdateTimeInterval-input {
    max-width: 200px;
}
.slideShowTime-input {
    max-width: 250px;
}
